/** @typedef {import("../types").StoredFile} StoredFile */
/** @type {number} */
const DB_VERSION = 3;

// Add connection pooling to avoid repeatedly opening connections
let dbConnection = null;

/**
 * Creates and opens IndexedDB database connection with connection pooling
 * @returns {Promise<IDBDatabase>}
 */
const openDatabase = () => {
  // Reuse existing connection if available
  if (dbConnection) {
    return Promise.resolve(dbConnection);
  }

  return new Promise((resolve, reject) => {
    const request = indexedDB.open('sayanshoSlidesDB', DB_VERSION);

    request.onerror = (event) => {
      const target = event.target;
      console.error(`Database error: ${target.error}`);
      reject(target.error);
    };

    request.onsuccess = (event) => {
      const target = event.target;
      dbConnection = target.result;

      // Handle connection losses
      dbConnection.onclose = () => {
        dbConnection = null;
      };

      resolve(dbConnection);
    };

    request.onupgradeneeded = (event) => {
      /** @type {IDBRequest} */
      const target = event.target;
      const db = target.result;
      if (!db.objectStoreNames.contains('files')) {
        const objectStore = db.createObjectStore('files', {
          keyPath: 'id',
          autoIncrement: true,
        });
        objectStore.createIndex('presentation', 'presentation', {
          unique: false,
        });
        objectStore.createIndex(
          'presentation_indexes',
          ['presentation', 'index'],
          { unique: false }
        );
      }
    };
  });
};

/**
 * Optimized batch loading using compound index
 */
const loadFilesFromDB = (db, presentationId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['files'], 'readonly');
    const objectStore = transaction.objectStore('files');
    const objectStoreIndex = objectStore.index('presentation_indexes');

    // Use getAll() instead of cursor for better performance when loading all slides
    const request = objectStoreIndex.getAll(
      IDBKeyRange.bound([presentationId, 0], [presentationId, Infinity])
    );

    request.onsuccess = (event) => {
      const target = event.target;
      resolve(target.result.map((item) => item.data));
    };

    request.onerror = (event) => {
      const target = event.target;
      reject(target.error);
    };
  });
};

/**
 * Optimized batch saving with transaction handling
 */
const saveFilesToDB = (db, files, presentationId, startIndex) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['files'], 'readwrite');
    const objectStore = transaction.objectStore('files');

    // Prepare all records first
    const records = files.map((file, index) => ({
      name: file.name,
      data: file,
      presentation: presentationId,
      index: startIndex + index,
    }));

    try {
      // Batch put operations
      records.forEach((record) => objectStore.put(record));

      transaction.oncomplete = () => resolve();
      transaction.onerror = (event) => {
        const target = event.target;
        reject(target.error);
      };
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Gets slide count for a presentation
 * @param {IDBDatabase} db - Database instance
 * @param {string} presentationId - Presentation ID
 * @returns {Promise<number>}
 */
const getSlideCount = (db, presentationId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['files'], 'readonly');
    const objectStore = transaction.objectStore('files');
    const objectStoreIndex = objectStore.index('presentation_indexes');
    const keyRange = IDBKeyRange.bound(
      [presentationId, 0],
      [presentationId, Infinity]
    );
    const request = objectStoreIndex.openCursor(keyRange);
    let count = 0;

    request.onsuccess = (event) => {
      /** @type {IDBRequest} */
      const target = event.target;
      const cursor = target.result;
      if (cursor) {
        count += 1;
        cursor.continue();
      } else {
        resolve(count);
      }
    };

    request.onerror = (target) => {
      /** @type {IDBRequest} */
      reject(target.error);
    };
  });
};

/**
 * Deletes all files for a presentation
 * @param {IDBDatabase} db - Database instance
 * @param {string} presentationId - Presentation ID
 * @returns {Promise<void>}
 */
const deletePresentationFiles = (db, presentationId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['files'], 'readwrite');
    const objectStore = transaction.objectStore('files');
    const objectStoreIndex = objectStore.index('presentation_indexes');
    const keyRange = IDBKeyRange.bound(
      [presentationId, 0],
      [presentationId, Infinity]
    );
    const request = objectStoreIndex.openCursor(keyRange);

    request.onsuccess = (event) => {
      /** @type {IDBRequest} */
      const target = event.target;
      const cursor = target.result;
      if (cursor) {
        cursor.continue();
      } else {
        resolve();
      }
    };

    request.onerror = (event) => {
      /** @type {IDBRequest} */
      const target = event.target;
      reject(target.error);
    };
  });
};


/**
 * Reorders files in the database based on new order
 * @param {IDBDatabase} db - Database instance
 * @param {Array<{name: string}>} files - Array of files with new order
 * @param {string} presentationId - Presentation ID
 * @returns {Promise<void>} 
 */
const reorderFiles = (db, files, presentationId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['files'], 'readwrite');
    const objectStore = transaction.objectStore('files');

    // Create a map of filename to new index for O(1) lookups
    const indexMap = new Map(
      files.map((file, index) => [file.name, index])
    );

    // Get all records in one go
    const request = objectStore.index('presentation_indexes').getAll(IDBKeyRange.bound(
      [presentationId, 0],
      [presentationId, Infinity]
    ));

    request.onsuccess = (event) => {
      const records = event.target.result;
      
      // Batch update all records
      records.forEach(record => {
        const newIndex = indexMap.get(record.name);
        if (newIndex !== undefined) {
          record.index = newIndex;
          objectStore.put(record);
        }
      });
    };

    transaction.oncomplete = () => resolve();
    transaction.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export {
  openDatabase,
  loadFilesFromDB,
  saveFilesToDB,
  getSlideCount,
  deletePresentationFiles,
  reorderFiles,
};
