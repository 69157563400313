/* global PARTYKIT_HOST */
import { makePartyRequest } from "../utils/functions";

/**
 * Gets all presentations for the current user
 * @returns {Promise<{error: string | null, presentations: Array<any> | null}>}
 */
async function getPresentations() {
  const result = await makePartyRequest({
    room: Clerk.user.id,
    path: 'get-presentations',
    method: 'GET'
  });
  
  if (result.success) {
    return { error: null, presentations: result.data.presentations };
  } else {
    return { 
      error: result.error || 'Error getting presentations',
      presentations: null 
    };
  }
}

/**
 * @returns {Promise<Array<{ id: string }> | null>}
 */
async function getUserPresentations() {
  const result = await makePartyRequest({
    room: Clerk.user.id,
    path: 'get-presentations',
    method: 'GET'
  });
  
  if (result.success) {
    return { 
      error: null, 
      existingPresentationIds: result.data.presentations 
    };
  } else {
    return {
      error: result.error || 'Error finding presentation',
      existingPresentationIds: null,
    };
  }
}

/**
 * Deletes a presentation's remote storage
 * @param {string} presentationId - The ID of the presentation to delete
 * @returns {Promise<{error: string | null, success: boolean}>}
 */
async function deleteRemotePresentationStorage(presentationId) {
  const result = await makePartyRequest({
    room: presentationId,
    method: 'DELETE'
  });
  
  return { 
    error: result.error, 
    success: result.success 
  };
}

/**
 * Adds a new presentation for the current user
 * @param {string} presentationName - The name of the presentation to create
 * @returns {Promise<{error: string | null, success: boolean}>}
 */
async function addPresentationToUser(presentationName) {
  const result = await makePartyRequest({
    room: Clerk.user.id,
    path: 'create-presentation',
    method: 'POST',
    body: {
      userId: Clerk.user.id,
      name: presentationName,
    },
  });
  
  return { 
    error: result.error, 
    success: result.success 
  };
}

/**
 * Deletes a presentation from the user's account
 * @param {string} presentationId - The ID of the presentation to delete
 * @returns {Promise<{error: string | null, success: boolean}>}
 */
async function deletePresentationFromUser(presentationId) {
  const result = await makePartyRequest({
    room: Clerk.user.id,
    path: 'delete-presentation',
    method: 'DELETE',
    body: { 
      userId: Clerk.user.id, 
      presentationId 
    }
  });
  
  return { 
    error: result.error, 
    success: result.success 
  };
}

export { 
  getUserPresentations, 
  deleteRemotePresentationStorage, 
  getPresentations,
  addPresentationToUser,
  deletePresentationFromUser
};
