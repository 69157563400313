export const reload = () => {
  window.location.reload();
};

/**
 * Navigates to a new URL
 * @param {string} url - The URL to navigate to
 */
export const navigate = (url) => {
  window.location.href = url;
};

/**
 * Adds an event listener for the 'load' event
 * @param {Function} callback - The callback function to execute when the 'load' event occurs
 */
export const onLoad = (callback) => {
  window.onload = callback;
};
