import { add } from './functions.js';
import { PRESENTATION_NOT_FOUND_ERROR, SUBSCRIPTION_REQUIRED_ERROR, SUBSCRIPTION_REQUIRED_WARNING } from './constants.js';
import { PRESENTATION_NOT_FOUND_WARNING } from './constants.js';
/**
 * Creates an error handler with consistent error management
 * @param {Object} [config] - Configuration object
 * @param {HTMLElement} [config.warningElement] - Warning container element
 * @param {HTMLElement} [config.warningMessage] - Warning message element
 * @param {HTMLElement} [config.successElement] - Success container element
 * @param {HTMLElement} [config.successMessage] - Success message element
 * @param {Function} [config.onFatal] - Callback for fatal errors
 * @param {Function} [config.onLog] - Logging callback function
 * @returns {Object} Error handling methods
 */
export const createErrorHandler = ({
  warningElement = document.querySelector('[data-warning]'),
  warningMessage = document.querySelector('[data-warning-message]'),
  successElement = document.querySelector('[data-success]'),
  successMessage = document.querySelector('[data-success-message]'),
  onFatal = () => {},
  onLog = add,
} = {}) => {
  /**
   * Shows a warning message
   * @param {string} message - The message to show
   * @param {boolean} isFatal - Whether the warning is fatal
   */
  const showWarning = (message, isFatal = false) => {
    if (!warningElement || !warningMessage) return;

    warningMessage.textContent = message;
    warningElement.hidden = false;
    onLog(`Warning: ${message}`);

    if (isFatal) {
      onFatal?.();
    }
  };

  /**
   * Shows a success message
   * @param {string} message - The message to show
   * @param {number} duration - The duration to show the message
   */
  const showSuccess = (message, duration = 0) => {
    if (!successElement || !successMessage) return;

    successMessage.textContent = message;
    successElement.hidden = false;
    onLog(`Success: ${message}`);

    if (duration > 0) {
      setTimeout(() => {
        successElement.hidden = true;
      }, duration);
    }
  };

  /**
   * Handles an error
   * @param {Error} error - The error to handle
   * @param {string} context - The context of the error
   */
  const handleError = (error, context = '') => {
    const message = `Error ${context}: ${error?.message ?? 'Error'}`;
    showWarning(message);
    console.error(message);
  };

  /**
   * Clears warnings
   */
  const clearWarnings = () => {
    if (warningElement) {
      warningElement.hidden = true;
    }
  };

  /**
   * Clears success messages
   */
  const clearSuccess = () => {
    if (successElement) {
      successElement.hidden = true;
    }
  };

  /**
   * Creates a custom error for presentation access issues
   * @param {boolean} validSubscription - Whether the user has a valid subscription
   * @param {string} presentationId - The ID of the presentation being accessed
   * @returns {Error} A custom error with appropriate message and properties
   */
  const createPresentationAccessError = (validSubscription, presentationId) => {
    const error = new Error(
      validSubscription
        ? PRESENTATION_NOT_FOUND_WARNING
        : SUBSCRIPTION_REQUIRED_WARNING
    );
    error.code = validSubscription
      ? PRESENTATION_NOT_FOUND_ERROR
      : SUBSCRIPTION_REQUIRED_ERROR;
    error.presentationId = presentationId;
    return error;
  };

  return {
    showWarning,
    showSuccess,
    handleError,
    clearWarnings,
    clearSuccess,
    onLog,
    createPresentationAccessError,
  };
};
