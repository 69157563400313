/**
 * Creates a keyboard event handler for navigation and controls
 * @param {Object} options Configuration object
 * @param {() => void} options.onNext Handler for next action
 * @param {() => void} options.onPrev Handler for previous action
 * @param {() => void} options.onEscape Handler for escape key
 * @param {() => void} [options.onFullScreen] Optional handler for fullscreen toggle
 * @returns {(event: KeyboardEvent) => void} Keyboard event handler
 */
export function createKeyboardHandler({
  onNext,
  onPrev,
  onEscape,
  onFullScreen,
}) {
  return (event) => {
    // Ignore repeated keypress events
    if (event.repeat) return;

    switch (event.key) {
      case 'ArrowRight':
        onNext();
        break;
      case 'ArrowLeft':
        onPrev();
        break;
      case 'f':
        onFullScreen?.();
        break;
      case 'Escape':
        onEscape();
        break;
    }
  };
} 