/**
 * Clones a template and appends it to a container
 * @param {string} templateSelector - Template element's data attribute name (without 'data-' prefix)
 * @param {string} containerSelector - Container element's query selector
 * @param {Object} [options] - Additional options
 * @param {boolean} [options.returnClone=false] - Whether to return the clone instead of appending
 * @returns {DocumentFragment|null} The cloned content if returnClone is true
 */
export const cloneTemplate = (
  templateSelector,
  containerSelector,
  options = {}
) => {
  try {
    const template = document.querySelector(`[data-${templateSelector}]`);
    const container = document.querySelector(containerSelector);

    if (!template) {
      console.warn(`Template not found: data-${templateSelector}`);
      return null;
    }

    if (!container) {
      console.warn(`Container not found: ${containerSelector}`);
      return null;
    }

    const clone = document.importNode(template.content, true);

    if (options.returnClone) {
      return clone;
    }

    container.appendChild(clone);
    return null;
  } catch (error) {
    console.error('Error cloning template:', error);
    return null;
  }
};

/**
 * @param {string} page
 */
export const clonePage = (page) => {
  const $main = document.querySelector('[data-main]');
  const $page = document.querySelector(`[data-${page}]`);
  const pageClone = document.importNode($page.content, true);
  $main.appendChild(pageClone);
  return pageClone;
}