import './styles.css';
import { createErrorHandler } from './utils/errors.js';
import { onLoad } from './utils/router.js';
import {
  DEFAULT_FATAL_WARNING,
  NOT_FOUND_PAGE,
  DASHBOARD_PAGE,
  PILOT_PAGE,
  COPILOT_PAGE,
  HOME_PAGE,
} from './utils/constants.js';
import { initialiseHomePage } from './pages/home.js';
import { initialiseNotFoundPage } from './pages/notFound.js';
import { togglePageLoader, resetPage } from './utils/page.js';
import { initialiseDashboardPage } from './pages/dashboard.js';
import { initialisePilotPage } from './pages/pilot.js';
import { initialiseCopilotPage } from './pages/copilot.js';
import { getUserPresentations } from './api/presentation.js';

// --------------------------
// VARIABLES
// --------------------------
/** @type {any} */
let router;

// --------------------------
// DOM ELEMENTS
// --------------------------
const $main = document.querySelector('[data-main]');

// --------------------------
// MANAGER SETUP
// --------------------------
let wsManager;
const errorHandler = createErrorHandler({
  onFatal: () =>
    resetPage({
      wsManager,
      mainElement: $main,
      toggleLoader: togglePageLoader,
    }),
});

// --------------------------
// AUTH
// --------------------------
/**
 * @returns {Promise<boolean>}
 */
async function initialiseAuth() {
  try {
    // Load Clerk
    await Clerk.load();
    // Return true if user is logged in
    return !!Clerk.user;
  } catch (error) {
    // Return false if user is not logged in
    return false;
  }
}

/**
 * Shows the sign in page
 * @returns {void}
 */
function showSignIn() {
  const $signInContainer = document.querySelector('[data-sign-in-container]');
  const $signInButton = document.querySelector('[data-sign-in]');
  $signInContainer.hidden = false;
  $signInButton.hidden = false;
  Clerk.mountSignIn($signInButton, {
    redirectUrl: `${router.getCurrentLocation().url}`,
  });
}

// --------------------------
// VERIFICATIONS
// --------------------------
/**
 * @param {string} id
 * @returns {Promise<boolean>}
 */
async function verifyPresentationExistsForUser(id) {
  const { existingPresentationIds, error } = await getUserPresentations();
  if (error) {
    errorHandler.showWarning(error?.message ?? 'Error', true);
    return false;
  }
  // Confirm presentation is in user presentation list
  return existingPresentationIds?.map((p) => p.id).includes(id) ?? false;
}

/**
 * @returns {boolean}
 */
function verifySubscription() {
  return Clerk.user?.unsafeMetadata?.stripe?.subscriptionActive ?? false;
}

// --------------------------
// PAGE HANDLERS
// --------------------------
const pageHandlers = {
  [PILOT_PAGE]: {
    requiresAuth: true,
    requiresSubscription: true,
    requiresVerification: true,
    handler: (presentationId) =>
      initialisePilotPage(presentationId ?? '', router),
  },
  [DASHBOARD_PAGE]: {
    requiresAuth: true,
    requiresSubscription: false,
    requiresVerification: false,
    handler: async () => {
      const validSubscription = verifySubscription();
      await initialiseDashboardPage(validSubscription);
    },
  },
  [COPILOT_PAGE]: {
    requiresAuth: false,
    requiresSubscription: false,
    requiresVerification: false,
    handler: (presentationId) => initialiseCopilotPage(presentationId ?? ''),
  },
  [HOME_PAGE]: {
    requiresAuth: false,
    requiresSubscription: false,
    requiresVerification: false,
    handler: () => initialiseHomePage(),
  },
  [NOT_FOUND_PAGE]: {
    requiresAuth: false,
    requiresSubscription: false,
    requiresVerification: false,
    handler: () => initialiseNotFoundPage(),
  },
};

/**
 * @param {{ page: string, presentationId: string | undefined }} options - The options for rendering the page.
 */
async function renderPage({ page, presentationId }) {
  try {
    resetPage({
      wsManager,
      mainElement: $main,
      toggleLoader: togglePageLoader,
    });

    // Get the handler for the current page or default to not found
    const pageConfig = pageHandlers[page] || pageHandlers[NOT_FOUND_PAGE];

    // Handle authentication if required
    if (pageConfig.requiresAuth) {
      const isAuthenticated = await initialiseAuth();
      if (!isAuthenticated) {
        return showSignIn();
      }

      // Check subscription if required
      const validSubscription = verifySubscription();
      if (pageConfig.requiresSubscription && !validSubscription) {
        throw errorHandler.createPresentationAccessError(
          false,
          presentationId ?? ''
        );
      }

      // Verify presentation access if required
      if (pageConfig.requiresVerification) {
        const validPresentation = await verifyPresentationExistsForUser(
          presentationId ?? ''
        );
        if (!validPresentation) {
          throw errorHandler.createPresentationAccessError(
            true,
            presentationId ?? ''
          );
        }
      }
    }

    // Execute the page handler
    await pageConfig.handler(presentationId);
  } catch (error) {
    if (error?.code === 'PRESENTATION_NOT_FOUND') {
      errorHandler.showWarning(
        `${error?.message ?? 'Error'}: ${error.presentationId ?? ''}`,
        true
      );
      router.navigate('/dashboard');
    } else if (error?.code === 'NO_SUBSCRIPTION') {
      errorHandler.showWarning(error?.message ?? 'Error', true);
      router.navigate('/dashboard');
    } else {
      errorHandler.showWarning(error?.message ?? DEFAULT_FATAL_WARNING, true);
    }
  }
}

// --------------------------
// ROUTER
// --------------------------
async function initialiseRouter() {
  router = new Navigo('/');

  // Set up simple routes
  router.on('/', () => renderPage({ page: HOME_PAGE }));
  router.on('/dashboard', () => renderPage({ page: DASHBOARD_PAGE }));

  // Set up parameterized routes
  router.on('/copilot/:id', (match) =>
    renderPage({
      page: COPILOT_PAGE,
      presentationId: match.data.id,
    })
  );

  router.on('/pilot/:id', (match) =>
    renderPage({
      page: PILOT_PAGE,
      presentationId: match.data.id,
    })
  );

  // Handle not found
  router.notFound(() => renderPage({ page: NOT_FOUND_PAGE }));

  router.resolve();
}

// --------------------------
// INITIALISE
// --------------------------
onLoad(initialiseRouter);
