/* global PARTYKIT_HOST */
import PartySocket from 'partysocket';
import { DEFAULT_FATAL_WARNING } from './constants.js';

/** @type {number} */
const MAX_RETRIES = 2;

/**
 * Creates a WebSocket manager with limited retry attempts
 * @param {Object} config - Configuration object
 * @param {string} [config.host] - WebSocket host
 * @param {string} config.room - Room/channel identifier
 * @param {Function} config.onMessage - Message handler
 * @param {Function} config.onError - Error handler
 * @param {Function} config.onLog - Logging function
 * @returns {Object} WebSocket operations
 */
export const createWebSocketManager = ({
  // @ts-ignore
  host = PARTYKIT_HOST,
  room,
  onMessage,
  onError = console.log,
  onLog = console.log,
}) => {
  /** @type {number} */
  let retryCount = 0;
  /** @type {boolean} */
  let connected = false;
  /** @type {PartySocket} */
  let socket;

  const connect = () => {
    return new Promise((resolve, reject) => {
      /** @type {PartySocket} - The connection object */
      const connection = new PartySocket({
        // host: 'http://192.168.86.33:60743', // <- use for local mobile testing
        host,
        room,
        maxRetries: MAX_RETRIES,
      });

      connection.onerror = () => {
        const maxReached = retryCount >= MAX_RETRIES;
        onLog(`   - WS error #${retryCount + 1}`);
        onError(
          `Attempting connection: ${retryCount + 1} of ${MAX_RETRIES}`,
          false
        );

        if (maxReached) {
          if (!connected) {
            // Reject will only work if connection error
            // is thrown on initial connection attempt e.g.
            // before resolve() called in onmessage() handler.
            reject();
          } else {
            // Initially connected and unable to reconnect
            onError(DEFAULT_FATAL_WARNING, true);
          }
        }
        retryCount += 1;
      };

      connection.onopen = () => {
        onLog('   - WS connection opened');
        retryCount = 0;
        connected = true;
        socket = connection;
        resolve(connection);
      };

      connection.onclose = () => {
        onLog('   - WS connection closed');
        connected = false;
        // @ts-ignore
        socket = null;
      };

      /** @param {Event} event - The message event */
      connection.onmessage = (event) => {
        // @ts-ignore
        const { type, status } = JSON.parse(event.data);
        onLog(
          `   - WS "${type}" message with status: ${JSON.stringify(status)} `
        );
        onMessage(type, status);
      };
    });
  };

  /**
   * Sends a message to the WebSocket server
   * @param {string|Object} data - The message to send
   */
  const send = (data) => {
    if (!socket) return;

    try {
      socket.send(typeof data === 'string' ? data : JSON.stringify(data));
    } catch (error) {
      onLog('Error sending message:', error);
    }
  };

  const disconnect = () => {
    if (socket) {
      try {
        onLog('   - WS disconnecting', socket);
        socket.close();
      } catch (e) {
        // Ignore errors during close
      }
      // @ts-ignore
      socket = null;
    }

    connected = false;
    retryCount = 0;
  };

  return {
    connect,
    send,
    disconnect,
  };
};
