/** @type {boolean} */
export const DEBUG = true;

/** @type {string} */
export const DEFAULT_FATAL_WARNING =
  'Error loading presentation, please try reloading the page';

/** @type {Record<string, string>} */
export const CORS_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE',
};

export const SUBSCRIPTION_REQUIRED_WARNING =
  'Subscription required to view presentation';

export const PRESENTATION_NOT_FOUND_WARNING =
  'Presentation not found';

export const SUBSCRIPTION_REQUIRED_ERROR = 'NO_SUBSCRIPTION';

export const PRESENTATION_NOT_FOUND_ERROR = 'PRESENTATION_NOT_FOUND';   

// Pages
/** @type {string} */
export const NOT_FOUND_PAGE = 'not_found';
/** @type {string} */
export const DASHBOARD_PAGE = 'dashboard';
/** @type {string} */
export const PILOT_PAGE = 'pilot';
/** @type {string} */
export const COPILOT_PAGE = 'copilot';
/** @type {string} */
export const HOME_PAGE = 'home';