import { COPILOT_PAGE, PILOT_PAGE } from './constants';
import { calculateProgress, formatSlideCount } from './functions';

/**
 * Creates a status manager for handling UI updates
 * @param {Object} elements - UI elements to update
 * @returns {Object} Status update methods
 */
export const createStatusManager = ({
  progressBar,
  infoElement,
  currentPage,
}) => {
  /**
   * Updates the progress bar
   * @param {number} current - The current slide
   * @param {number} total - The total number of slides
   */
  const updateProgress = (current, total) => {
    progressBar.style.width = !total
      ? 0
      : `${calculateProgress(current, total)}%`;
  };

  /**
   * Updates the info element
   * @param {number} index - The current slide
   * @param {number} total - The total number of slides
   */
  const updateInfo = (index, total) => {
    if (currentPage === COPILOT_PAGE) {
      infoElement.textContent = total
        ? `${index} of ${total}`
        : 'No slides found';
    } else if (currentPage === PILOT_PAGE) {
      infoElement.textContent = total
        ? `Slide ${formatSlideCount(index, total)}`
        : 'No slides found';
    }
    updateProgress(index, total);
  };

  return { updateProgress, updateInfo };
};

// Usage:
// const statusManager = createStatusManager({
//   progressBar: document.querySelector('[data-progress-bar]'),
//   infoElement: document.querySelector('[data-info]'),
//   currentPage
// });
