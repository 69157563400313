import PartySocket from 'partysocket';
import { DEBUG } from './constants';

/**
 * Calculates progress percentage
 * @param {number} current - Current index
 * @param {number} total - Total items
 * @returns {number} Progress percentage
 */
export const calculateProgress = (current, total) => (current / total) * 100;

/**
 * Validates file type
 * @param {File | import("../types").StoredFile} file - File to validate
 * @returns {boolean} Is valid file type
 */
export const isValidFileType = (file) =>
  ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);

/**
 * Formats slide count text
 * @param {number} current - Current slide
 * @param {number} total - Total slides
 * @returns {string} Formatted text
 */
export const formatSlideCount = (current, total) => `${current} / ${total}`;

/**
 * Ensures index is within bounds
 * @param {number} index - Current index
 * @param {number} total - Total items
 * @returns {number} Safe index
 */
export const getSafeIndex = (index, total) =>
  Math.max(0, Math.min(index, total - 1));

/**
 * Creates and tracks blob URLs for cleanup
 * @param {File} file - File to create URL from
 * @returns {string} Object URL
 */
export const createFileUrl = (() => {
  /**
   * @type {string | null}
   */
  let currentUrl = null;

  return (/** @type {Blob | MediaSource} */ file) => {
    if (currentUrl) {
      URL.revokeObjectURL(currentUrl);
    }
    currentUrl = URL.createObjectURL(file);
    return currentUrl;
  };
})();

/** @param {string} text - The text to be added */
export const add = (text) => {
  if (DEBUG) console.log(text);
};


/**
 * Formats a date
 * @param {number} timestamp - The timestamp
 * @returns {string} Formatted date
 */
export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  // Use the browser's default locale, falling back to en-US if needed
  const userLocale = navigator.language || 'en-US';
  return date.toLocaleString(userLocale, options);
};

/**
 * Helper function to make PartySocket fetch requests
 * @param {Object} options - Request options
 * @param {string} options.room - Room ID for the request
 * @param {string} [options.path] - Optional path for the request
 * @param {string} options.method - HTTP method (GET, POST, DELETE, etc.)
 * @param {Object} [options.body] - Optional request body
 * @returns {Promise<{error: string | null, data: any | null, success: boolean}>}
 */
export async function makePartyRequest(options) {
  const { room, path, method, body } = options;
  
  try {
    const fetchOptions = {
      method,
    };
    
    if (body) {
      fetchOptions.body = JSON.stringify(body);
    }
    
    const fetchParams = {
      // @ts-ignore
      host: PARTYKIT_HOST,
      room,
    };
    
    if (path) {
      fetchParams.path = path;
    }
    
    const response = await PartySocket.fetch(fetchParams, fetchOptions);
    
    if (response.ok) {
      const data = await response.json();
      return { error: null, data, success: true };
    } else {
      return { 
        error: `Error with request: ${response.statusText}`,
        data: null,
        success: false 
      };
    }
  } catch (error) {
    return {
      error: `Request error: ${error?.message ?? 'Error'}`,
      data: null,
      success: false,
    };
  }
}