import { DEFAULT_FATAL_WARNING } from '../utils/constants.js';
import { clonePage, cloneTemplate } from '../utils/dom.js';
import { createModalManager } from '../utils/modal.js';
import { createStatusManager } from '../utils/status.js';
import { createErrorHandler } from '../utils/errors.js';
import { createWebSocketManager } from '../utils/websocket.js';
import {
  toggleFullScreen,
  togglePageLoader,
  resetPage,
} from '../utils/page.js';
import { createKeyboardHandler } from '../utils/keyboard.js';
import { syncStatus } from '../utils/sync.js';

// --------------------------
// VARIABLES
// --------------------------
// Misc
/** @type {number} */
let index = 0;
/** @type {number} */
let total = 0;

// DOM Elements
const copilotElements = {};

// --------------------------
// MANAGER SETUP
// --------------------------
let statusManager;
let wsManager;
const modalManager = createModalManager();
const errorHandler = createErrorHandler({
  onFatal: () =>
    resetPage({
      wsManager,
      mainElement: copilotElements.$main,
      toggleLoader: togglePageLoader,
    }),
});

// --------------------------
// HANDLERS
// --------------------------
const handleKeydown = createKeyboardHandler({
  onNext: handleNext,
  onPrev: handlePrev,
  onEscape: () => modalManager.hide(),
  onFullScreen: toggleFullScreen,
});

const handlePrev = () => {
  index > 0 && syncWithRemotes(index - 1, total);
};

const handleNext = () => {
  index < total - 1 && syncWithRemotes(index + 1, total);
};

/**
 * Handles incoming WebSocket messages
 * @param {string} type - Message type
 * @param {object} status - Status data
 */
function handleWebSocketMessage(type, status) {
  if (type === 'init' || type === 'status') {
    index = status.index;
    total = status.total;
    statusManager?.updateInfo(index + 1, total);
  }
}

// --------------------------
// WEBSOCKET
// --------------------------
// Update remote store with selected index and total
/**
 * @param {number} newIndex
 * @param {number} totalSlides
 */
function syncWithRemotes(newIndex, totalSlides) {
  // Update local index variable
  index = newIndex;
  // Update local total variable
  total = totalSlides;

  syncStatus(newIndex, totalSlides, {
    statusManager,
    wsManager,
    onLog: errorHandler.onLog,
  });
}

// Initialise page
/** @param {string} id */
async function initialiseCopilotPage(id) {
  try {
    // Setup phase
    await setupWebSocket(id);
    togglePageLoader(false);

    // DOM setup phase
    setupDomElements();

    // Initialize status manager and update display
    initializeStatusManager();
    statusManager.updateInfo(index + 1, total);
  } catch (e) {
    errorHandler.showWarning(DEFAULT_FATAL_WARNING, true);
  }
}

/**
 * Sets up WebSocket connection
 * @param {string} id - Room ID
 */
async function setupWebSocket(id) {
  wsManager = createWebSocketManager({
    room: id,
    onMessage: handleWebSocketMessage,
    onError: errorHandler.showWarning,
    onLog: errorHandler.onLog,
  });

  errorHandler.onLog('1. Connect WS');
  await wsManager.connect();
}

/**
 * Sets up DOM elements and event listeners
 */
function setupDomElements() {
  // Clone page and templates
  clonePage('copilot-page');
  cloneTemplate('logo-template', '[data-copilot_logo]');
  cloneTemplate('progress-bar-template', '[data-copilot_pb]');

  // Cache DOM references
  copilotElements.$main = document.querySelector('[data-main]');
  copilotElements.$prevButton = document.querySelector(
    '[data-copilot_prev-btn]'
  );
  copilotElements.$nextButton = document.querySelector(
    '[data-copilot_next-btn]'
  );
  copilotElements.$progressBar = document.querySelector('[data-progress-bar]');
  copilotElements.$infoElement = document.querySelector('[data-copilot_info]');
  copilotElements.$copilotLogo = document.querySelector('[data-copilot_logo]');
  copilotElements.$copilotPb = document.querySelector('[data-copilot_pb]');

  // Add event listeners using cached references
  copilotElements.$prevButton?.addEventListener('click', handlePrev);
  copilotElements.$nextButton?.addEventListener('click', handleNext);
  document.addEventListener('keydown', handleKeydown);
}

/**
 * Initializes the status manager
 */
function initializeStatusManager() {
  statusManager = createStatusManager({
    progressBar: copilotElements.$progressBar,
    infoElement: copilotElements.$infoElement,
    currentPage: 'copilot',
  });
}

export { initialiseCopilotPage };
