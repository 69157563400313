/* global PARTYKIT_HOST */
import { makePartyRequest } from '../utils/functions';

/**
 * Creates a Stripe checkout session for the user
 * @param {string} userId - The ID of the user
 * @returns {Promise<{error: string | null, session: any | null}>}
 */
async function createStripeSession(userId) {
  const result = await makePartyRequest({
    room: userId,
    method: 'POST',
    body: { userId },
  });

  if (result.success) {
    return { error: null, session: result.data };
  } else {
    return {
      error: result.error || 'Failed to create Stripe session',
      session: null,
    };
  }
}

/**
 * Creates a Stripe customer portal session
 * @param {string} stripeCustomerId - The Stripe customer ID
 * @returns {Promise<{error: string | null, url: string | null}>}
 */
async function createStripePortalSession(stripeCustomerId) {
  const result = await makePartyRequest({
    room: Clerk.user.id,
    path: 'create-customer-portal-session',
    method: 'POST',
    body: { stripeCustomerId },
  });

  if (result.success) {
    return { error: null, url: result.data.url };
  } else {
    return {
      error: result.error || 'Error initializing subscription portal',
      url: null,
    };
  }
}

export { createStripeSession, createStripePortalSession };
