import { clonePage, cloneTemplate } from '../utils/dom.js';
import { togglePageLoader, resetPage } from '../utils/page.js';
import {
  deletePresentationFiles,
  getSlideCount,
  openDatabase,
} from '../database/db.js';
import { createWebSocketManager } from '../utils/websocket.js';
import { navigate, reload } from '../utils/router.js';
import { createModalManager } from '../utils/modal.js';
import { createErrorHandler } from '../utils/errors.js';
import { formatDate } from '../utils/functions.js';
import {
  deleteRemotePresentationStorage,
  getPresentations,
  addPresentationToUser,
  deletePresentationFromUser,
} from '../api/presentation.js';
import {
  createStripeSession,
  createStripePortalSession,
} from '../api/stripe.js';

// --------------------------
// VARIABLES
// --------------------------
/** @type {IDBDatabase | undefined} */
let db;

// --------------------------
// DOM ELEMENTS
// --------------------------
const dashboardElements = {};

// --------------------------
// MANAGER SETUP
// --------------------------
let wsManager;
const modalManager = createModalManager();
const errorHandler = createErrorHandler({
  onFatal: () =>
    resetPage({
      wsManager,
      mainElement: document.querySelector('[data-main]'),
      toggleLoader: togglePageLoader,
    }),
});

// --------------------------
// HANDLERS
// --------------------------
const handleCreate = async () => {
  // Update user metadata (clerk) with presentation id
  const presentationName = prompt('Enter a name for your presentation:');

  if (!presentationName) {
    errorHandler.showWarning('Presentation name is required', false);
    return;
  }

  const { error, success } = await addPresentationToUser(presentationName);
  if (error) {
    errorHandler.showWarning(error, false);
    return;
  }

  if (success) {
    // Reload page
    reload();
  }
};

/**
 * @param {string} id
 */
const handleDeletePresentation = (id) => {
  return () => {
    // Clear modal content
    modalManager.setContent(null);
    const t_deletePresentation = document.querySelector(
      '[data-delete-presentation-template]'
    );
    const clone = document.importNode(t_deletePresentation.content, true);
    modalManager.setContent(clone);
    modalManager.show();
    document
      .querySelector('[data-presentation-delete-confirm-button]')
      .addEventListener('click', deletePresentation(id));
    document
      .querySelector('[data-presentation-delete-cancel-button]')
      .addEventListener('click', modalManager.hide);
  };
};

/**
 * @param {{ id: string; primaryEmailAddress: any; unsafeMetadata?: { presentationIds: never[]; }; }} user
 */
const handleSubscribe = (user) => {
  return async () => {
    try {
      const stripe = initialisePaymentProcessing();
      const { error, session } = await createStripeSession(user.id);

      if (error) {
        errorHandler.showWarning(error, false);
        return;
      }

      await stripe.redirectToCheckout({
        sessionId: session.id,
      });
    } catch (error) {
      errorHandler.showWarning(
        `Error initializing checkout: ${error?.message ?? 'Error'}`,
        false
      );
    }
  };
};

// --------------------------
// PAYMENT PROCESSING
// --------------------------
function initialisePaymentProcessing() {
  try {
    const stripe = window.Stripe('pk_test_zaIdds0qIW5lPldD7F4axjyB');
    return stripe;
  } catch (error) {
    errorHandler.onLog(error);
    return false;
  }
}

function redirectToBillingPortal(user) {
  return async () => {
    try {
      const stripeCustomerId = user?.unsafeMetadata?.stripe?.stripeCustomerId;
      const { error, url } = await createStripePortalSession(stripeCustomerId);

      if (error) {
        errorHandler.showWarning(error, false);
        return;
      }

      if (url) {
        navigate(url);
      }
    } catch (error) {
      errorHandler.showWarning(
        `Error initializing subscription portal: ${error?.message ?? 'Error'}`,
        false
      );
    }
  };
}

// --------------------------
// PRESENTATION MANAGEMENT
// --------------------------
/**
 * @param {string} id
 */
const deletePresentation = (id) => {
  return async () => {
    const { error, success } = await deletePresentationFromUser(id);
    if (success) {
      // Clear local storage
      await deletePresentationFiles(db, id);
      // Clear remote storage
      const result = await deleteRemotePresentationStorage(id);
      if (result.error) {
        errorHandler.onLog(result.error);
      } else if (result.success) {
        errorHandler.onLog(`Presentation ${id} deleted successfully.`);
      }
      // Reload page
      reload();
    } else if (error) {
      errorHandler.showWarning(error, false);
      modalManager.hide();
    } else {
      modalManager.hide();
    }
  };
};

/**
 * Clones a template, populates it with data, and appends it to the specified container.
 *
 * @param {HTMLElement | null} presentationsList - The container element where the cloned template will be appended.
 * @param {HTMLTemplateElement | null} template - The template element to clone (or null).
 * @param {{name: string, count: number, link: string, createdAt: string}} presentationData - The data to populate the template, including name and link.
 * @returns {void}
 */
function addPresentation(presentationsList, template, presentationData) {
  if (!presentationsList) {
    throw new Error('List element is null. Ensure the list exists in the DOM.');
  } else if (!template) {
    throw new Error(
      'Template element is null. Ensure the template exists in the DOM.'
    );
  }
  // Clone the template content
  const clone = document.importNode(template.content, true);
  // Target specific elements using data-* attributes
  const presentationName = clone.querySelector('[data-presentation-name]');
  if (presentationName) {
    presentationName.textContent = presentationData.name;
  }
  const presentationCount = clone.querySelector(
    '[data-presentation-slide-count]'
  );
  if (presentationCount) {
    presentationCount.textContent = `${presentationData.count.toString()} slide${
      presentationData.count !== 1 ? 's' : ''
    }`;
  }
  const presentationCreatedAt = clone.querySelector(
    '[data-presentation-created-at]'
  );
  if (presentationCreatedAt) {
    presentationCreatedAt.textContent = `Created ${presentationData.createdAt}`;
  }
  /** @type {HTMLAnchorElement | null} */
  const presentationLink = clone.querySelector('[data-presentation-link]');
  if (presentationLink) {
    presentationLink.href = presentationData.link;
  }
  const presentationDelete = clone.querySelector('[data-presentation-delete]');
  if (presentationDelete) {
    presentationDelete?.addEventListener(
      'click',
      handleDeletePresentation(presentationData.id)
    );
  }
  // Append the cloned template to the list
  presentationsList.appendChild(clone);
}

async function loadPresentations(isMember) {
  // Use cached DOM references
  const $presentationsList = dashboardElements.$presentationsList;
  const t_li = /** @type {HTMLTemplateElement | null} */ (
    dashboardElements.$presentationLiTemplate
  );
  const noPresentations = dashboardElements.$noPresentations;
  const noPresentationsMsg = dashboardElements.$noPresentationsMsg;

  // Show loading
  noPresentations.hidden = false;
  noPresentationsMsg.innerHTML = 'Loading presentations...';

  // Get presentations from API
  const { error, presentations } = await getPresentations();

  if (error) {
    errorHandler.showWarning(error, false);
    noPresentationsMsg.innerHTML = 'Error loading presentations.';
    return;
  }

  if (!presentations?.length) {
    noPresentations.hidden = false;
    noPresentationsMsg.innerHTML = isMember
      ? 'No presentations created, click the Create new presentation button to get started.'
      : 'Subscribe to create a presentation.';
  } else {
    noPresentations.hidden = true;
    presentations.forEach(async ({ id, name, createdAt }) => {
      const count = await getSlideCount(db, id);
      const presentation = {
        id,
        name,
        count,
        link: `/pilot/${id}`,
        createdAt: formatDate(createdAt),
      };
      addPresentation($presentationsList, t_li, presentation);
    });
  }
}

/**
 * Initializes the dashboard page
 * @param {boolean} isMember - Whether the user is a member
 * @returns {Promise<void>}
 */
async function initialiseDashboardPage(isMember) {
  // Setup phase
  await setupWebSocket();
  await setupDatabase();
  togglePageLoader(false);

  // DOM setup phase
  setupDomElements(isMember);

  // Load presentation list
  loadPresentations(isMember);
}

async function setupWebSocket() {
  wsManager = createWebSocketManager({
    room: Clerk.user.id,
    onMessage: (type, status) => {
      // Logic based on event type
      if (type === 'subscription') {
        if (!status.subscription && Clerk.user.id === status.clerkUserId) {
          reload();
        }
      }
    },
    onError: errorHandler.showWarning,
    onLog: errorHandler.onLog,
  });
  await wsManager.connect();
}

async function setupDatabase(id) {
  errorHandler.onLog('2. Open DB');
  try {
    db = await openDatabase();
  } catch (error) {
    throw new Error(`opening database - ${error?.message ?? 'Error'}`);
  }
}

/**
 * Sets up DOM elements and event listeners
 */
function setupDomElements(isMember) {
  // Clone page and templates
  clonePage('dashboard-page');
  cloneTemplate('logo-template', '[data-copilot_logo]');

  // Get references to DOM elements
  setupDashboardElements();

  // Setup subscribe button
  setupSubscribeButton(isMember);

  // Add event listeners
  setupEventListeners();
}

function setupDashboardElements() {
  // --- CACHE DOM ELEMENTS --- //
  dashboardElements.$userButton = document.querySelector(
    '[data-copilot_user-button]'
  );
  dashboardElements.$createButton = document.querySelector(
    '[data-create-button]'
  );
  dashboardElements.$subscribeButton = document.querySelector(
    '[data-subscribe-button]'
  );
  dashboardElements.$manageSubscriptionButton = document.querySelector(
    '[data-manage-subscription-button]'
  );
  dashboardElements.$presentationsList = document.querySelector(
    '[data-presentations-list]'
  );
  dashboardElements.$noPresentations = document.querySelector(
    '[data-no-presentations]'
  );
  dashboardElements.$noPresentationsMsg = document.querySelector(
    '[data-no-presentations-message]'
  );
  dashboardElements.$presentationLiTemplate = document.querySelector(
    '[data-presentation-li-template]'
  );

  // Add Clerk user button
  Clerk.mountUserButton(dashboardElements.$userButton);
}

function setupSubscribeButton(isMember) {
  // Hide/show subscribe button
  if (isMember) {
    dashboardElements.$subscribeButton.hidden = true;
    dashboardElements.$manageSubscriptionButton.hidden = false;
    dashboardElements.$createButton.hidden = false;
  } else {
    dashboardElements.$subscribeButton.hidden = false;
    dashboardElements.$manageSubscriptionButton.hidden = true;
    dashboardElements.$createButton.hidden = true;
  }
}

function setupEventListeners() {
  dashboardElements.$createButton?.addEventListener('click', handleCreate);
  dashboardElements.$subscribeButton?.addEventListener(
    'click',
    handleSubscribe(Clerk.user)
  );
  dashboardElements.$manageSubscriptionButton?.addEventListener(
    'click',
    redirectToBillingPortal(Clerk.user)
  );
}

export { initialiseDashboardPage };
