/**
 * Updates local state and syncs with remote clients
 * @param {number} newIndex - New index value
 * @param {number} totalSlides - Total number of slides
 * @param {Object} params - Additional parameters
 * @param {Object} params.statusManager - Status manager instance
 * @param {Object} params.wsManager - WebSocket manager instance
 * @param {Function} [params.displaySlide] - Optional function to display slide
 * @param {Function} params.onLog - Logging function
 */
export function syncStatus(newIndex, totalSlides, {
  statusManager,
  wsManager,
  displaySlide,
  onLog
}) {
  onLog?.(`Sync index: ${newIndex} t: ${totalSlides}`);

  // Update info
  statusManager.updateInfo(newIndex + 1, totalSlides);

  // Display slide if function provided
  displaySlide?.(newIndex, totalSlides);

  // Sync index using WS
  wsManager.send({ 
    type: 'status', 
    status: { 
      index: newIndex, 
      total: totalSlides 
    } 
  });
} 