/**
 * Creates a modal manager with standard operations
 * @param {Object} [elements] - Modal DOM elements
 * @param {HTMLElement} [elements.modal] - The modal container
 * @param {HTMLElement} [elements.backdrop] - The modal backdrop
 * @param {HTMLElement} [elements.panel] - The modal panel
 * @param {HTMLElement} [elements.content] - The modal content container
 * @throws {Error} If required modal elements are not found
 * @returns {Object} Modal operations
 */
export const createModalManager = ({
  modal = document.querySelector('[data-modal]'),
  backdrop = document.querySelector('[data-modal-backdrop]'),
  panel = document.querySelector('[data-modal-panel]'),
  content = document.querySelector('[data-modal-content]'),
} = {}) => {
  // Validate required elements exist
  if (!modal || !backdrop || !panel || !content) {
    throw new Error('Required modal elements not found');
  }

  // Add event listeners storage
  const hideListeners = new Set();

  /**
   * Shows the modal
   */
  const show = () => {
    modal.hidden = false;
    backdrop?.classList.remove('opacity-0');
    backdrop?.classList.add('opacity-100');
    panel?.classList.remove(
      'opacity-0',
      'translate-y-4',
      'sm:translate-y-0',
      'sm:scale-95'
    );
    panel?.classList.add('opacity-100', 'translate-y-0', 'sm:scale-100');
    document.addEventListener('mousedown', handleOutsideClick);
  };

  /**
   * Hides the modal
   */
  const hide = () => {
    modal.hidden = true;
    backdrop?.classList.add('opacity-0');
    backdrop?.classList.remove('opacity-100');
    panel?.classList.add(
      'opacity-0',
      'translate-y-4',
      'sm:translate-y-0',
      'sm:scale-95'
    );
    panel?.classList.remove('opacity-100', 'translate-y-0', 'sm:scale-100');
    document.removeEventListener('mousedown', handleOutsideClick);

    // Notify all listeners
    hideListeners.forEach((listener) => listener());
  };

  /**
   * Sets the content of the modal
   * @param {HTMLElement} element - The element to set as the modal content
   */
  const setContent = (element, onHide) => {
    content.innerHTML = '';
    if (element) {
      content.appendChild(element);
    }
    // Add hide listener if provided
    if (onHide) {
      hideListeners.add(onHide);
    }
    return () => {
      // Return cleanup function to remove listener
      hideListeners.delete(onHide);
    };
  };

  /**
   * Handles outside clicks on the modal
   * @param {MouseEvent} e - The mouse event
   */
  const handleOutsideClick = (e) => {
    if (!panel?.contains(e.target)) {
      hide();
    }
  };

  return {
    show,
    hide,
    setContent,
  };
};
