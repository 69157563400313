/**
 * Toggles the page loader visibility
 * @param {boolean} show - Whether to show or hide the loader
 * @returns {void}
 */
export function togglePageLoader(show) {
  const $main = document.querySelector('[data-main]');

  if (show) {
    // Clone logo
    const t_logo = document.querySelector('[data-logo-template]');
    const clone = document.importNode(t_logo.content, true);
    $main?.classList.add('grid', 'place-items-center');
    $main.appendChild(clone);
    const $logoSVG = document.querySelector('[data-logo-svg]');
    $logoSVG?.classList.add('animate-pulse');
  } else {
    $main?.classList.remove('grid', 'place-items-center');
    $main.innerHTML = '';
  }
}

/**
 * Toggles full screen mode
 * @returns {void}
 */
export function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

/**
 * Resets the page to the initial state
 * @param {Object} options - Reset options
 * @param {Object} [options.wsManager] - WebSocket manager to disconnect
 * @param {HTMLElement} [options.mainElement] - Main element to clear
 * @param {Function} [options.toggleLoader] - Function to toggle page loader
 * @returns {void}
 */
export function resetPage({ wsManager, mainElement, toggleLoader } = {}) {
  // Disconnect WebSocket if provided
  if (wsManager) {
    wsManager.disconnect();
  }
  
  // Clear main element content if provided
  if (mainElement) {
    mainElement.innerHTML = '';
  }
  
  // Show loader if toggle function provided
  if (toggleLoader) {
    toggleLoader(true);
  }
}