import { clonePage, cloneTemplate } from '../utils/dom.js';
import { togglePageLoader } from '../utils/page.js';

/**
 * Initializes the home page
 * @returns {void}
 */
export function initialiseHomePage() {
  // Remove loader
  togglePageLoader(false);
  
  // Allow scroll
  document.querySelector('body')?.classList.remove('overflow-hidden');
  document.querySelector('body')?.classList.add('overflow-auto');
  
  // --- CLONE PAGE --- //
  clonePage('home-page');
  
  // Clone logo
  cloneTemplate('logo-template', '[data-home_logo]');
} 