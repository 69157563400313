import { clonePage } from '../utils/dom.js';
import { togglePageLoader } from '../utils/page.js';

/**
 * Initializes the not found page
 * @returns {void}
 */
export function initialiseNotFoundPage() {
  // Remove loader
  togglePageLoader(false);
  
  // --- CLONE PAGE --- //
  clonePage('not-found-page');
} 