/** @typedef {import("../types").StoredFile} StoredFile */
import { DEFAULT_FATAL_WARNING } from '../utils/constants.js';
import {
  isValidFileType,
  createFileUrl,
  getSafeIndex,
} from '../utils/functions.js';
import { clonePage, cloneTemplate } from '../utils/dom';
import {
  deletePresentationFiles,
  saveFilesToDB,
  openDatabase,
  loadFilesFromDB,
  reorderFiles,
} from '../database/db.js';
import { createStatusManager } from '../utils/status';
import { createWebSocketManager } from '../utils/websocket.js';
import {
  toggleFullScreen,
  togglePageLoader,
  resetPage,
} from '../utils/page.js';
import { createModalManager } from '../utils/modal.js';
import { createErrorHandler } from '../utils/errors.js';
import { reload } from '../utils/router.js';
import { createKeyboardHandler } from '../utils/keyboard.js';
import { syncStatus } from '../utils/sync.js';

// --------------------------
// VARIABLES
// --------------------------
// Misc
/** @type {any} */
let router;
/** @type {IDBDatabase | undefined} */
let db;
/** @type {number} */
let index = 0;
/** @type {number} */
let total = 0;
/** @type {StoredFile[]} */
let slides = [];
/** @type {string} */
let presentationId;
/** @type {boolean} */
let showingMenu = false;

// --------------------------
// DOM ELEMENTS
// --------------------------
const pilotElements = {};

// --------------------------
// MANAGER SETUP
// --------------------------
let statusManager;
let wsManager;
const modalManager = createModalManager();
const errorHandler = createErrorHandler({
  onFatal: () =>
    resetPage({
      wsManager,
      mainElement: document.querySelector('[data-main]'),
      toggleLoader: togglePageLoader,
    }),
});

// --------------------------
// HANDLERS
// --------------------------
const handlePrev = () => {
  index > 0 && syncWithRemotes(index - 1, total);
};

const handleNext = () => {
  index < total - 1 && syncWithRemotes(index + 1, total);
};

const handleKeydown = createKeyboardHandler({
  onNext: handleNext,
  onPrev: handlePrev,
  onEscape: () => modalManager.hide(),
  onFullScreen: toggleFullScreen,
});

/**
 * Handles menu item selection
 * @param {Event} event - The click event
 */
const handleMenuSelect = async (event) => {
  const name = event.target.getAttribute('data-menu-item');

  // Process the menu selection
  const shouldShowModal = await processMenuSelection(name);

  // Close the menu
  handleMenu();

  // Show modal if needed
  if (shouldShowModal) {
    modalManager.show();
  }
};

// Show slide
const handleDisplaySlides = () => {
  reload();
};

// File upload
const handleUpload = () => {
  pilotElements.$fileInput.click();
};

/**
 * Handles file input change event when user selects files
 * @param {Event} event - The file input change event
 */
const handleFileInputChange = async (event) => {
  const files = Array.from(event.target.files);

  if (!files.length) {
    return; // No files selected, exit early
  }

  // Update UI to show uploading state
  handleUploadStateChange(true, false);

  try {
    // Add new files to slides array
    slides = [...slides, ...files];

    // Save files to database
    await saveFilesToDB(db, files, presentationId, slides.length - 1);

    // Handle successful upload
    handleUploadSuccess(files.length);
  } catch (error) {
    // Handle any errors during the process
    handleUploadError(error);
  } finally {
    // Reset the file input to allow selecting the same files again
    event.target.value = '';
  }
};

const handleUploadStateChange = (uploading, hasSlides) => {
  pilotElements.$uploadButton.hidden = uploading;
  pilotElements.$uploadingButton.hidden = !uploading;
  pilotElements.$displaySlidesButton.hidden = !hasSlides;
};

/** @param {number} count */
const handleUploadSuccess = (count) => {
  errorHandler.showSuccess(
    `Successfully added ${count} file${count !== 1 ? 's' : ''}`
  );
  handleUploadStateChange(false, true);
};

/** @param {unknown} error */
const handleUploadError = (error) => {
  errorHandler.handleError(error, 'uploading files');
  handleUploadStateChange(false, false);
};

const handleMenu = () => {
  showingMenu = !showingMenu;
  toggleMenu(showingMenu);
};

const handleShareCopyButtonClick = (input, button) => {
  return () => {
    navigator.clipboard.writeText(input.value).then(() => {
      button.classList.add('text-cyan-600');
      setTimeout(() => {
        button.classList.remove('text-cyan-600');
      }, 500);
    });
  };
};

/** @param {any} event */
const handleDeleteAll = (id) => {
  return async (event) => {
    try {
      await deletePresentationFiles(db, id);
      // Reset index and sync with remotes
      syncWithRemotes(0, 0);
      // Reload page
      reload();
    } catch (error) {
      errorHandler.onLog('Error deleting presentation files from DB:', error);
    }
  };
};

/**
 * Creates a click handler for reordering slides
 * @param {HTMLUListElement} $slidesList - The slides list element
 * @returns {(e: MouseEvent) => void} Click event handler
 */
const handleSlideOrderClick = ($slidesList) => (e) => {
  const upButton = e.target.closest('[data-slide-up]');
  const downButton = e.target.closest('[data-slide-down]');

  if (upButton || downButton) {
    const listItem = e.target.closest('li');
    if (!listItem) return;

    const items = Array.from($slidesList.children);
    const currentIndex = items.indexOf(listItem);

    if (upButton && currentIndex > 0) {
      // Move up
      $slidesList.insertBefore(listItem, items[currentIndex - 1]);
      saveNewOrder($slidesList);
    } else if (downButton && currentIndex < items.length - 1) {
      // Move down
      $slidesList.insertBefore(items[currentIndex + 1], listItem);
      saveNewOrder($slidesList);
    }
  }
};

/**
 * Handles incoming WebSocket messages
 * @param {string} type - Message type
 * @param {object} status - Status data
 */
const handleWebSocketMessage = (type, status) => {
  if (type === 'init' || type === 'status') {
    index = status.index;
    total = status.total;
    if (type === 'status') {
      displaySlide(index, total);
    }
  }
};

// --------------------------
// WEBSOCKET
// --------------------------
// Update remote store with selected index and total
/**
 * @param {number} newIndex
 * @param {number} totalSlides
 */
function syncWithRemotes(newIndex, totalSlides) {
  // Update local index variable
  index = newIndex;
  // Update local total variable
  total = totalSlides;

  syncStatus(newIndex, totalSlides, {
    statusManager,
    wsManager,
    displaySlide,
    onLog: errorHandler.onLog,
  });
}

// --------------------------
// MENU
// --------------------------
/**
 * Processes the selected menu item
 * @param {string} menuItem - The selected menu item
 * @returns {Promise<boolean>} - Whether to show the modal after processing
 */
async function processMenuSelection(menuItem) {
  switch (menuItem) {
    case 'order-slides':
      setupOrderSlidesModal();
      return true;

    case 'share':
      setupShareModal();
      return true;

    case 'shortcuts':
      setupShortcutsModal();
      return true;

    case 'delete':
      if (slides.length) {
        setupDeleteModal();
        return true;
      }
      return false;

    case 'upload':
      handleUpload();
      return false;

    case 'sign-out':
      await Clerk.signOut();
      router.navigate('/');
      reload();
      return false;

    case 'dashboard':
      router.navigate('/dashboard');
      return false;

    default:
      return false;
  }
}

async function loadSlides(newClone) {
  const $slidesList = newClone.querySelector('[data-slides-list]');
  // Use cached DOM references
  const t_li = /** @type {HTMLTemplateElement | null} */ (
    pilotElements.$slideLiTemplate
  );

  // Clear existing slides first
  $slidesList.innerHTML = '';

  // Create and append all slides
  slides.forEach((slide, index) => {
    const clone = document.importNode(t_li.content, true);

    // Set slide name
    const slideName = clone.querySelector('[data-slide-name]');
    if (slideName) {
      slideName.textContent = slide.name;
    }

    // Add data-id attribute to the list item
    const listItem = clone.querySelector('li');
    if (listItem) {
      listItem.setAttribute('data-slide-id', index.toString());
    }

    $slidesList.appendChild(clone);
  });

  // Add click handlers for up/down buttons
  $slidesList.addEventListener('click', handleSlideOrderClick($slidesList));
}

async function saveNewOrder(list) {
  const newlyOrderedFiles = Array.from(list.children).map(
    (item) => slides[item.getAttribute('data-slide-id')]
  );

  try {
    await reorderFiles(db, newlyOrderedFiles, presentationId);
  } catch (error) {
    errorHandler.handleError(error, 'ordering files');
  }
}

/**
 * Sets up the order slides modal content
 */
async function setupOrderSlidesModal() {
  const clone = cloneTemplate('order-slides-template', '[data-modal-content]', {
    returnClone: true,
  });

  if (clone) {
    await loadSlides(clone);
    // Create modal and reload page on modal hide
    modalManager.setContent(clone, reload);
  }
}

/**
 * Sets up the share modal content
 */
function setupShareModal() {
  const clone = cloneTemplate('share-template', '[data-modal-content]', {
    returnClone: true,
  });

  if (clone) {
    const $link = clone.querySelector('[data-shareLink]');
    $link.value = window.location.href.replace('/pilot/', '/copilot/');

    const $shareCopyButton = clone.querySelector('[data-share-copy-button]');
    $shareCopyButton.addEventListener(
      'click',
      handleShareCopyButtonClick($link, $shareCopyButton)
    );

    modalManager.setContent(clone);
  }
}

/**
 * Sets up the keyboard shortcuts modal content
 */
function setupShortcutsModal() {
  const clone = cloneTemplate(
    'keyboard-shortcuts-template',
    '[data-modal-content]',
    { returnClone: true }
  );

  modalManager.setContent(clone);
}

/**
 * Sets up the delete confirmation modal content
 */
function setupDeleteModal() {
  const clone = cloneTemplate('delete-all-template', '[data-modal-content]', {
    returnClone: true,
  });

  if (clone) {
    clone
      .querySelector('[data-cancel-delete-all-button]')
      .addEventListener('click', modalManager.hide);

    clone
      .querySelector('[data-do-delete-all-button]')
      .addEventListener('click', handleDeleteAll(presentationId));

    modalManager.setContent(clone);
  }
}

// --------------------------
// UPDATE ELEMENTS CONTENT
// --------------------------
/** @param {number} index */
function displaySlide(index, total) {
  const file = slides[getSafeIndex(index, slides.length)];

  // Only display files of type: image
  if (file && isValidFileType(file)) {
    pilotElements.$slide.src = createFileUrl(file);
  }

  // Update info
  statusManager.updateInfo(index + 1, total);
}

// --------------------------
// HIDE/SHOW ELEMENTS
// --------------------------
/** @param {boolean} show */
function toggleUpload(show) {
  pilotElements.$upload.hidden = !show;
}

/** @param {boolean} show */
function toggleLoader(show) {
  pilotElements.$loader.hidden = !show;
}

/** @param {boolean} show */
function toggleMenu(show) {
  pilotElements.$menu.hidden = !show;
}

// Initialise page
/**
 * @param {string} newPresentationId
 * @param {any} newRouter
 */
async function initialisePilotPage(newPresentationId, newRouter) {
  try {
    // Initialize global variables
    router = newRouter;
    presentationId = newPresentationId;

    // Setup phase
    await setupWebSocket(presentationId);
    await setupDatabase(presentationId);
    togglePageLoader(false);

    // DOM setup phase
    setupDomElements();

    // Display content phase
    if (slides.length) {
      syncWithRemotes(index, slides.length);
    } else {
      toggleUpload(true);
    }

    // Reveal slide image
    pilotElements.$slide.hidden = false;
  } catch (e) {
    errorHandler.showWarning(DEFAULT_FATAL_WARNING, true);
  }
}

/**
 * Sets up WebSocket connection
 */
async function setupWebSocket(id) {
  wsManager = createWebSocketManager({
    room: id,
    onMessage: handleWebSocketMessage,
    onError: errorHandler.showWarning,
    onLog: errorHandler.onLog,
  });

  errorHandler.onLog('1. Connect WS');
  await wsManager.connect();
}

/**
 * Sets up database connection and loads slides
 */
async function setupDatabase(id) {
  errorHandler.onLog('2. Open DB');
  try {
    db = await openDatabase();
    slides = await loadFilesFromDB(db, id);
  } catch (error) {
    throw new Error(`opening database - ${error?.message ?? 'Error'}`);
  }
}

/**
 * Sets up DOM elements and event listeners
 */
function setupDomElements() {
  // Clone page and templates
  clonePage('pilot-page');
  cloneTemplate('logo-template', '[data-footer-logo]');
  cloneTemplate('progress-bar-template', '[data-pilot_pb]');

  // Get references to DOM elements
  setupPilotElements();

  // Add event listeners
  setupEventListeners();

  // Hide loader
  toggleLoader(false);

  // Initialize status manager
  initializeStatusManager();
  statusManager.updateInfo(index + 1, total);
}

/**
 * Sets up references to pilot page DOM elements
 */
function setupPilotElements() {
  // Slide
  pilotElements.$slide = document.querySelector('[data-slide]');
  pilotElements.$loader = document.querySelector('[data-loader]');

  // Upload
  pilotElements.$fileInput = document.querySelector('[data-file-input]');
  pilotElements.$upload = document.querySelector('[data-upload]');
  pilotElements.$uploadButton = document.querySelector('[data-upload-button]');
  pilotElements.$uploadingButton = document.querySelector(
    '[data-uploading-button]'
  );
  pilotElements.$displaySlidesButton = document.querySelector(
    '[data-display-slides-button]'
  );

  // Menu
  pilotElements.$menu = document.querySelector('[data-menu]');

  // Add these elements to the cache
  pilotElements.$nextButton = document.querySelector('[data-next]');
  pilotElements.$prevButton = document.querySelector('[data-prev]');
  pilotElements.$menuButton = document.querySelector('[data-menu-button]');
  pilotElements.$menuItemShare = document.querySelector(
    '[data-menu-item-share]'
  );
  pilotElements.$menuItemOrderSlides = document.querySelector(
    '[data-menu-item-order-slides]'
  );
  pilotElements.$menuItemShortcuts = document.querySelector(
    '[data-menu-item-shortcuts]'
  );
  pilotElements.$menuItemDashboard = document.querySelector(
    '[data-menu-item-dashboard]'
  );
  pilotElements.$menuItemSignOut = document.querySelector(
    '[data-menu-item-sign-out]'
  );
  pilotElements.$menuItemUpload = document.querySelector(
    '[data-menu-item-upload]'
  );
  pilotElements.$menuItemDelete = document.querySelector(
    '[data-menu-item-delete]'
  );
  pilotElements.$modalCloseBtn = document.querySelector(
    '[data-modal-close-btn]'
  );
  pilotElements.$reloadButton = document.querySelector('[data-reload-button]');
  pilotElements.$closeSuccessButton = document.querySelector(
    '[data-close-success-button]'
  );
  pilotElements.$closeWarningButton = document.querySelector(
    '[data-close-warning-button]'
  );
  pilotElements.$slideLiTemplate = document.querySelector(
    '[data-slide-li-template]'
  );
}

/**
 * Sets up event listeners for pilot page elements
 */
function setupEventListeners() {
  // Controls - use cached references
  pilotElements.$nextButton?.addEventListener('click', handleNext);
  pilotElements.$prevButton?.addEventListener('click', handlePrev);
  pilotElements.$menuButton?.addEventListener('click', handleMenu);

  // Upload
  pilotElements.$fileInput?.addEventListener('change', handleFileInputChange);
  pilotElements.$uploadButton?.addEventListener('click', handleUpload);
  pilotElements.$displaySlidesButton?.addEventListener(
    'click',
    handleDisplaySlides
  );

  // Menu items - use cached references
  pilotElements.$menuItemShare?.addEventListener('click', handleMenuSelect);
  pilotElements.$menuItemOrderSlides?.addEventListener(
    'click',
    handleMenuSelect
  );
  pilotElements.$menuItemShortcuts?.addEventListener('click', handleMenuSelect);
  pilotElements.$menuItemDashboard?.addEventListener('click', handleMenuSelect);
  pilotElements.$menuItemSignOut?.addEventListener('click', handleMenuSelect);
  pilotElements.$menuItemUpload?.addEventListener('click', handleMenuSelect);
  pilotElements.$menuItemDelete?.addEventListener('click', handleMenuSelect);

  pilotElements.$modalCloseBtn?.addEventListener('click', modalManager.hide);
  pilotElements.$reloadButton?.addEventListener('click', handleDisplaySlides);
  pilotElements.$closeSuccessButton?.addEventListener(
    'click',
    errorHandler.clearSuccess
  );
  pilotElements.$closeWarningButton?.addEventListener(
    'click',
    errorHandler.clearWarnings
  );
  document.addEventListener('keydown', handleKeydown);
}

/**
 * Initializes the status manager
 */
function initializeStatusManager() {
  statusManager = createStatusManager({
    progressBar: document.querySelector('[data-progress-bar]'),
    infoElement: document.querySelector('[data-info]'),
    currentPage: 'pilot',
  });
}

export { initialisePilotPage };
