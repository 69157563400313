/* eslint-env browser */
/* global PARTYKIT_HOST */
import './styles.css';
import PartySocket from 'partysocket';

/**
 * @typedef {Object} StoredFile
 * @property {string} name - The name of the file.
 * @property {File} data - The actual file object.
 * @property {string} room - The room ID associated with this file.
 * @property {number} index - The slide index for ordering.
 * @property {string} type - The slide type for determining display.
 */

// --------------------------
// VARIABLES
// --------------------------
/** @type {number} */
const DB_VERSION = 2;
/** @type {boolean} */
const DEBUG = true;
/** @type {number} */
const MAX_RETRIES = 2;
// Pages
/** @type {string} */
const NOT_FOUND_PAGE = 'not_found';
/** @type {string} */
const PILOT_PAGE = 'pilot';
/** @type {string} */
const COPILOT_PAGE = 'copilot';
/** @type {string} */
const HOME_PAGE = 'home';
// Misc
/** @type {any} */
let router;
/** @type {PartySocket} */
let socket;
/** @type {IDBDatabase | undefined} */
let db;
/** @type {number} */
let index = 0;
/** @type {number} */
let total = 0;
/** @type {StoredFile[]} */
let slides = [];
/** @type {number} */
let retryCount = 0;
/** @type {string} */
let room;
/** @type {boolean} */
let showingMenu = false;
/** @type {string} */
let currentPage = NOT_FOUND_PAGE;

/** @param {string} text - The text to be added */
function add(text) {
  if (DEBUG) console.log(text);
}

// --------------------------
// DOM ELEMENTS
// --------------------------
const $main = document.getElementById('main');
// Status
const $warning = document.getElementById('warning');
const $success = document.getElementById('success');
const $successMessage = document.getElementById('success-message');
const $warningMessage = document.getElementById('warning-message');
const $reloadButton = document.getElementById('reload-button');
const $closeSuccessButton = document.getElementById('close-success-button');
const $closeWarningButton = document.getElementById('close-warning-button');
// Pilot
const pilotElements = {};
// Copilot
const copilotElements = {};
// Templates
const t_share = document.getElementById('share-template');
const t_keyboardShortcuts = document.getElementById(
  'keyboard-shortcuts-template'
);
const t_deleteAll = document.getElementById('delete-all-template');

// --------------------------
// HIDE/SHOW ELEMENTS
// --------------------------
function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

/** @param {boolean} show */
function toggleUpload(show) {
  if (show) {
    pilotElements.$upload.classList.remove('hidden');
  } else {
    pilotElements.$upload.classList.toggle('hidden');
  }
}

/** @param {boolean} show */
function toggleLoader(show) {
  if (show) {
    pilotElements.$loader.classList.remove('hidden');
  } else {
    pilotElements.$loader.classList.toggle('hidden');
  }
}

/**
 * @param {string | null} message
 * @param {boolean} [show=true]
 */
function toggleSuccess(message, show = true) {
  if (show) {
    $successMessage.textContent = message;
    $success.classList.remove('hidden');
  } else {
    $success.classList.add('hidden');
  }
}

/**
 * @param {string | null} message
 * @param {boolean} [show=true]
 */
function toggleWarning(message, show = true) {
  if (show) {
    $warningMessage.textContent = message;
    $warning.classList.remove('hidden');
  } else {
    $warning.classList.add('hidden');
  }
}

/** @param {boolean} show */
function toggleMenu(show) {
  if (show) {
    pilotElements.$menu.classList.remove('hidden');
  } else {
    pilotElements.$menu.classList.add('hidden');
  }
}
/** @type {string} */
const DEFAULT_FATAL_WARNING =
  'Error loading room, please try reloading the page';

/** @param {string} [msg=DEFAULT_FATAL_WARNING] - The warning message to display */
function toggleFatalWarning(msg = DEFAULT_FATAL_WARNING) {
  toggleWarning(msg);
  $main.innerHTML = '';
  togglePageLoader(true);
}

/** @param {boolean} show */
function togglePageLoader(show) {
  if (show) {
    // Clone logo
    const t_logo = document.getElementById('logo-template');
    const clone = document.importNode(t_logo.content, true);
    $main?.classList.add('grid', 'place-items-center');
    $main.appendChild(clone);
    const $logoSVG = document.getElementById('logo-svg');
    $logoSVG?.classList.add('animate-pulse');
  } else {
    $main?.classList.remove('grid', 'place-items-center');
    $main.innerHTML = '';
  }
}

function showSignIn() {
  const match = router.getCurrentLocation();
  const $signInContainer = document.getElementById('sign-in-container');
  const $signInButton = document.getElementById('sign-in');
  $signInContainer.classList.remove('hidden');
  $signInButton.classList.remove('hidden');
  Clerk.mountSignIn($signInButton, {
    redirectUrl: `${match.url}`,
  });
}

function resetPages() {
  // TODO: do this better e.g. specific to previous page using router
  $main.innerHTML = '';
  togglePageLoader(true);
}

/** @param {string} id */
function checkForRoom(id) {
  if (!id) {
    throw new Error('Could not find the room! Check the URL and try again.');
  }
}

// --------------------------
// UPDATE ELEMENTS CONTENT
// --------------------------
/** @param {number} n */
function displaySlide(n) {
  const file = slides[n];
  // Only display files of type: image
  if (file && file.type.startsWith('image/')) {
    add('4. Display slide');
    // Select local files and then read the contents of those files.
    const reader = new FileReader();

    // File read operation has begun.
    reader.onloadstart = () => {
      add(`   - Loading slide ${n + 1} progress: 0%`);
    };

    // Fired periodically as the FileReader reads data.
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentLoaded = Math.round((event.loaded / event.total) * 100);
        add(`   - Loading slide ${n + 1} progress: ${percentLoaded}%`);
      }
    };

    // Fired when file has been read successfully.
    reader.onload = (event) => {
      // Set image src to slide
      pilotElements.$slide.src = event.target.result;
      // Update info
      updateInfo(n + 1);
    };

    // Read contents of specified file
    reader.readAsDataURL(file);
  } else {
    // Update info for copilot
    updateInfo(n + 1);
  }
}

/** @param {number} n */
function updateInfo(n) {
  if (currentPage === COPILOT_PAGE) {
    copilotElements.$copilotInfo.textContent = total
      ? `${n} of ${total}`
      : 'No slides found';
  } else if (currentPage === PILOT_PAGE) {
    pilotElements.$pilotInfo.textContent = total
      ? `Slide ${n} / ${total}`
      : 'No slides found';
  }
  // Update progress bar
  updateProgressBar(total ? n : 0);
}

/** @param {number} n */
function updateProgressBar(n) {
  const progress = (n / total) * 100;
  const $progressBarContainer = document.getElementById('progress-bar');
  $progressBarContainer.setAttribute('style', `width:${progress || 0}%`);
}

// --------------------------
// HANDLERS
// --------------------------
// Navigation
function handlePrev() {
  index > 0 && sync(index - 1, total);
}

function handleNext() {
  index < total - 1 && sync(index + 1, total);
}

// Toasts
function handleCloseSuccess() {
  $success?.classList.toggle('hidden');
}

function handleCloseWarning() {
  $warning?.classList.toggle('hidden');
}

// Show slide
function handleDisplaySlides() {
  window.location.reload();
}

// File upload
function handleUpload() {
  pilotElements.$fileInput.click();
}

/** @param {{ target: { files: Iterable<any> | ArrayLike<any>; }; }} event */
async function handleFileInputChange(event) {
  const files = Array.from(event.target.files);
  console.log(files);
  if (files.length > 0) {
    handleUploadStateChange();
    try {
      slides = slides.concat(files);
      saveFilesToDB(files, room, slides.length - 1);
      handleUploadSuccess(files.length);
    } catch (error) {
      handleUploadError(error);
    }
  }
}

function handleUploadStateChange() {
  pilotElements.$uploadButton?.classList.add('hidden');
  pilotElements.$uploadingButton?.classList.remove('hidden');
}

/** @param {number} n */
function handleUploadSuccess(n) {
  toggleSuccess(`Upload of ${n} file${n > 1 ? 's' : ''} was successfull 👍`);
  pilotElements.$uploadButton?.classList.remove('hidden');
  pilotElements.$uploadingButton?.classList.add('hidden');
  pilotElements.$displaySlidesButton?.classList.remove('hidden');
}

/** @param {unknown} error */
function handleUploadError(error) {
  toggleWarning(`Error uploading file(s) ${error.message} 😢`);
  pilotElements.$uploadButton?.classList.remove('hidden');
  pilotElements.$uploadingButton?.classList.add('hidden');
}

// Info
function handleMenu() {
  showingMenu = !showingMenu;
  toggleMenu(showingMenu);
}

// Keydown
/** @param {{ repeat: any; key: string; }} event */
function handleKeydown(event) {
  if (!event.repeat) {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    } else if (event.key === 'f') {
      toggleFullScreen();
    } else if (event.key === 'Escape') {
      handleModalClose();
    }
  }
}

// Menu selections
/** @param {boolean} show */
function toggleModal(show) {
  if (show) {
    pilotElements.$modal?.classList.remove('hidden');
    pilotElements.$modalBackdrop?.classList.remove('opacity-0');
    pilotElements.$modalBackdrop?.classList.add('opacity-100');
    pilotElements.$modalPanel?.classList.remove(
      'opacity-0',
      'translate-y-4',
      'sm:translate-y-0',
      'sm:scale-95'
    );
    pilotElements.$modalPanel?.classList.add(
      'opacity-100',
      'translate-y-0',
      'sm:scale-100'
    );
  } else {
    pilotElements.$modal?.classList.add('hidden');
    pilotElements.$modalBackdrop?.classList.add('opacity-0');
    pilotElements.$modalBackdrop?.classList.remove('opacity-100');
    pilotElements.$modalPanel?.classList.add(
      'opacity-0',
      'translate-y-4',
      'sm:translate-y-0',
      'sm:scale-95'
    );
    pilotElements.$modalPanel?.classList.remove(
      'opacity-100',
      'translate-y-0',
      'sm:scale-100'
    );
  }
}

/** @param {{ target: { getAttribute: (arg0: string) => any; }; }} event */
async function handleMenuSelect(event) {
  const name = event.target.getAttribute('data-menu-item');
  // Clear modal content
  pilotElements.$modalContent.innerHTML = '';
  let clone;

  if (name === 'share') {
    clone = document.importNode(t_share.content, true);
    clone.getElementById('share-link').value = window.location.origin;
    pilotElements.$modalContent.appendChild(clone);
  } else if (name === 'shortcuts') {
    clone = document.importNode(t_keyboardShortcuts.content, true);
    pilotElements.$modalContent.appendChild(clone);
  } else if (name === 'delete') {
    if (slides.length) {
      clone = document.importNode(t_deleteAll.content, true);
      // Add action button event handlers
      clone
        .getElementById('cancel-delete-all-button')
        .addEventListener('click', handleModalClose);
      clone
        .getElementById('do-delete-all-button')
        .addEventListener('click', handleDoDeleteAll);
      pilotElements.$modalContent.appendChild(clone);
    } else {
      return;
    }
  } else if (name === 'upload') {
    handleMenu();
    return handleUpload();
  } else if (name === 'sign-out') {
    await Clerk.signOut();
    router.navigate('/');
    return window.location.reload();
  }

  handleMenu();
  toggleModal(true);
}

// Modal
function handleModalClose() {
  toggleModal(false);
}

/** @param {any} event */
function handleDoDeleteAll(event) {
  deleteFilesFromDB(handleModalClose);
}
// --------------------------
// WEBSOCKET
// --------------------------
/** @param {string} id */
function initialiseWS(id) {
  add('1. Connect WS');
  return connectWS(id)
    .then((connection) => (socket = connection))
    .catch((error) => {
      throw new Error(`connecting to websocket`);
    });
}

/** @param {string} id */
function connectWS(id) {
  let connected = false;
  return new Promise((resolve, reject) => {
    /**
     * A PartySocket is like a WebSocket, but with more features.
     * It handles reconnection logic, buffering messages while it's offline, etc.
     * @type {PartySocket} - The connection object
     */
    const connection = new PartySocket({
      // @ts-expect-error This should be typed as a global string
      // host: 'http://192.168.86.33:60743', // <- use for local mobile testing
      host: PARTYKIT_HOST,
      room: id,
      maxRetries: MAX_RETRIES,
    });

    connection.onerror = () => {
      retryCount += 1;
      add(`   - WS error #${retryCount}`);
      const maxReached = retryCount === MAX_RETRIES;
      toggleWarning(`Attempting connection: ${retryCount} of ${MAX_RETRIES}`);
      if (maxReached) {
        if (!connected) {
          // Reject will only work if connection error
          // is thrown on initial connection attempt e.g.
          // before resolve() called in onmessage() handler.
          reject();
        } else {
          // Initially connected and unable to reconnect
          toggleFatalWarning();
        }
      }
    };

    connection.onopen = () => {
      add('   - WS connection opened');
      retryCount = 0;
      connected = true;
    };

    /** @param {Event} event - The message event */
    connection.onmessage = (event) => {
      const { type, status } = JSON.parse(event.data);
      // Logic based on event type
      if (type === 'init') {
        // Initial connection message providing stored index and total values
        add(`   - WS message init status: ${status.index}/${status.total}`);
        index = status.index;
        total = status.total;
        // Initialised connection and received index, resolve promise to load rest of UI
        resolve(connection);
      } else if (type === 'sync') {
        // Update index and total values
        add(`   - WS message sync status: ${status.index}/${status.total}`);
        index = status.index;
        total = status.total;
        // Display the slide based on index
        displaySlide(status.index);
      }
    };
  });
}

// Update remote store with selected index and total

/**
 * @param {number} n
 * @param {number} t
 */
function sync(n, t) {
  add(`Sync index: ${n} t: ${t}`);
  // Update local index variable
  index = n;
  // Update local total variable
  total = t;
  // Display slide at specified index
  displaySlide(n);
  // Sync index using WS
  socket.send(JSON.stringify({ index, total }));
}

// --------------------------
// DATABASE
// --------------------------
/** @param {string} id */
function initialiseDB(id) {
  add('2. Open DB');
  return loadFilesFromDB(id).catch((error) => {
    throw new Error(`opening database - ${error.message}`);
  });
}

function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('sayanshoSlidesDB', DB_VERSION);

    request.onerror = (event) => {
      add(`   Database error: ${event.target.error}`);
      reject(event.target.error);
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      add('   - Database opened successfully.');
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains('files')) {
        const objectStore = db.createObjectStore('files', {
          keyPath: 'id',
          autoIncrement: true,
        });
        objectStore.createIndex('room', 'room', { unique: false });
        objectStore.createIndex('room_indexes', ['room', 'index'], {
          unique: false,
        });
        add('   Object store and index created.');
      }
    };
  });
}

/** @param {string} id */
async function loadFilesFromDB(id) {
  return new Promise(async (resolve, reject) => {
    // Ensure database is open and object store exists
    openDatabase()
      .then(() => {
        add('3. Load slides from DB');
        const transaction = db.transaction(['files'], 'readonly');
        const objectStore = transaction.objectStore('files');
        let objectStoreIndex;

        try {
          objectStoreIndex = objectStore.index('room_indexes');
        } catch (e) {
          add(`   - Room indexes does not exist: ${e.message}`);
          reject(e);
        }

        // Create a key range to query the roomId and order index
        const keyRange = IDBKeyRange.bound([id, 0], [id, Infinity]);

        // Selects all the records in this object store
        // and iterates throught them all
        const request = objectStoreIndex.openCursor(keyRange);
        add('   - Iterate object store records.');

        request.onsuccess = (
          /** @type {{ target: { result: { continue: any, value: { data: StoredFile }}; }; }} */ event
        ) => {
          const cursor = event.target.result;
          if (cursor) {
            // cursor.value contains the current record being iterated through
            slides.push(cursor.value.data);
            add(`     - Adding ${cursor.value.data.name} to slides.`);
            cursor.continue();
          } else {
            resolve(slides);
          }
        };

        request.onerror = (
          /** @type {{ target: { error: any; }; }} */ event
        ) => {
          add(
            `   - Error fetching files from DB: ${event.target.error.message}`
          );
          // throw event.target.error;
          reject(event.target.error);
        };
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * @param {StoredFile[]} files
 * @param {string} id
 * @param {number} slidesLength
 */
function saveFilesToDB(files, id, slidesLength) {
  const transaction = db.transaction(['files'], 'readwrite');
  const objectStore = transaction.objectStore('files');
  add('Saving files to DB');
  files.forEach((file, n) => {
    const fileData = {
      name: file.name,
      data: file,
      room: id,
      index: slidesLength + n,
    };
    objectStore.put(fileData);
  });

  transaction.oncomplete = () => {
    add('All files saved in DB.');
  };

  transaction.onerror = (/** @type {{ target: { error: any; }; }} */ event) => {
    console.error('Transaction error saving files to DB:', event.target.error);
  };
}

/** @param {{ (): void; (): void; }} cb */
function deleteFilesFromDB(cb) {
  const transaction = db.transaction(['files'], 'readwrite');
  const objectStore = transaction.objectStore('files');

  const request = objectStore.clear();

  request.onsuccess = () => {
    // Reset slides array
    slides = [];
    // Reset image src
    pilotElements.$slide.src =
      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    // Reset index and sync with remotes
    sync(0, 0);
    // Reset display info
    updateInfo(0);
    // Reset progress bar
    toggleUpload(true);
    // Close modal
    cb();
    add('All files deleted from DB.');
  };

  request.onerror = (/** @type {{ target: { error: any; }; }} */ event) => {
    console.error('Error deleting files from DB:', event.target.error);
  };
}

function reorderFilesInDB() {
  const transaction = db.transaction(['files'], 'readwrite');
  const objectStore = transaction.objectStore('files');

  slides.forEach((file, n) => {
    const request = objectStore.get(file.name);
    request.onsuccess = (
      /** @type {{ target: { result: StoredFile; }; }} */ event
    ) => {
      const fileData = event.target.result;
      fileData.index = n;
      objectStore.put(fileData);
    };
  });

  transaction.oncomplete = () => {
    add('Files reordered in DB.');
    slides.sort((a, b) => a.index - b.index);
    displaySlide(index);
  };

  transaction.onerror = (/** @type {{ target: { error: any; }; }} */ event) => {
    console.error('Transaction error:', event.target.error);
  };
}

// --------------------------
// PAGES
// --------------------------
/** @param {string} id */
async function initialisePilot(id) {
  try {
    // Simple check for an id in url
    checkForRoom(id);
    // Assign room to var
    room = id;
    // Initialise WebSocket
    await initialiseWS(id);
    // Initialise DB
    await initialiseDB(id);
    // Remove loader
    togglePageLoader(false);
    // --- CLONE PAGE --- //
    clonePage('pilot-page');
    // --- ELEMENTS --- //
    const t_pb = document.getElementById('progress-bar-template');
    const t_logo = document.getElementById('logo-template');
    const $footerLogo = document.getElementById('footer-logo');
    const $progressBarContainer = document.getElementById('pilot_pb');
    pilotElements.$pilotInfo = document.getElementById('info');
    // Controls
    const $nextButton = document.getElementById('next');
    const $prevButton = document.getElementById('prev');
    const $menuButton = document.getElementById('menu-button');
    // Slide
    pilotElements.$slide = document.getElementById('slide');
    pilotElements.$loader = document.getElementById('loader');
    // Upload
    pilotElements.$fileInput = document.getElementById('file-input');
    pilotElements.$upload = document.getElementById('upload');
    pilotElements.$uploadButton = document.getElementById('upload-button');
    pilotElements.$uploadingButton =
      document.getElementById('uploading-button');
    // Menu
    pilotElements.$menu = document.getElementById('menu');
    const $menuShare = document.getElementById('menu-item-share');
    const $menuShortcuts = document.getElementById('menu-item-shortcuts');
    const $menuSignOut = document.getElementById('menu-item-sign-out');
    const $menuUpload = document.getElementById('menu-item-upload');
    const $menuDelete = document.getElementById('menu-item-delete');
    // Modal
    pilotElements.$modal = document.getElementById('modal');
    pilotElements.$modalBackdrop = document.getElementById('modal-backdrop');
    pilotElements.$modalPanel = document.getElementById('modal-panel');
    pilotElements.$modalContent = document.getElementById('modal-content');
    const $modalCloseButton = document.getElementById('modal-close-btn');
    // Clone logo
    const clone = document.importNode(t_logo.content, true);
    $footerLogo.appendChild(clone);
    // Clone progress bar
    const $progressBarClone = document.importNode(t_pb.content, true);
    $progressBarContainer.appendChild($progressBarClone);
    // --- HANDLERS --- //
    $nextButton?.addEventListener('click', handleNext);
    $prevButton?.addEventListener('click', handlePrev);
    pilotElements.$fileInput?.addEventListener('change', handleFileInputChange);
    pilotElements.$uploadButton?.addEventListener('click', handleUpload);
    $menuButton?.addEventListener('click', handleMenu);
    $menuShare?.addEventListener('click', handleMenuSelect);
    $menuShortcuts?.addEventListener('click', handleMenuSelect);
    $menuSignOut?.addEventListener('click', handleMenuSelect);
    $menuUpload?.addEventListener('click', handleMenuSelect);
    $menuDelete?.addEventListener('click', handleMenuSelect);
    $modalCloseButton?.addEventListener('click', handleModalClose);
    pilotElements.$displaySlidesButton?.addEventListener(
      'click',
      handleDisplaySlides
    );
    // Hide loader
    toggleLoader(false);
    // Either display slide or show upload field
    if (slides.length) {
      sync(index, slides.length);
    } else {
      // If no slides, show upload window to upload some slides
      toggleUpload(true);
    }
    // Reveal slide img
    pilotElements.$slide?.classList.remove('hidden');
  } catch (e) {
    toggleFatalWarning();
  }
}

/** @param {string} id */
async function initialiseCopilot(id) {
  try {
    checkForRoom(id);
    // Assign room to var
    room = id;
    // Initialise WebSocket
    await initialiseWS(id);
    // Remove loader
    togglePageLoader(false);
    // --- CLONE PAGE --- //
    clonePage('copilot-page');
    // --- ELEMENTS --- //
    const t_pb = document.getElementById('progress-bar-template');
    const t_logo = document.getElementById('logo-template');
    const $copilotLogo = document.getElementById('copilot_logo');
    const $progressBarContainer = document.getElementById('copilot_pb');
    const $prevBtn = document.getElementById('copilot_prev-btn');
    const $nextBtn = document.getElementById('copilot_next-btn');
    const $userButton = document.getElementById('copilot_user-button');
    copilotElements.$copilotInfo = document.getElementById('copilot_info');
    // Clone logo
    const logoClone = document.importNode(t_logo.content, true);
    $copilotLogo.appendChild(logoClone);
    // Clone progress bar
    const $progressBarClone = document.importNode(t_pb.content, true);
    $progressBarContainer.appendChild($progressBarClone);
    // Add Clerk user button
    Clerk.mountUserButton($userButton);
    // --- HANDLERS --- //
    $prevBtn?.addEventListener('click', handlePrev);
    $nextBtn?.addEventListener('click', handleNext);
    // Update info
    updateInfo(index + 1);
  } catch (e) {
    toggleFatalWarning();
  }
}

function initialiseHomePage() {
  // Remove loader
  togglePageLoader(false);
  // Allow scroll
  document.querySelector('body')?.classList.remove('overflow-hidden');
  document.querySelector('body')?.classList.add('overflow-auto');
  // --- CLONE PAGE --- //
  clonePage('home-page');
  // --- ELEMENTS --- //
  const t_logo = document.getElementById('logo-template');
  const $homeLogo = document.getElementById('home_logo');
  // Clone logo
  const logoClone = document.importNode(t_logo.content, true);
  $homeLogo.appendChild(logoClone);
}

function initialiseNotFoundPage() {
  // Remove loader
  togglePageLoader(false);
  // --- CLONE PAGE --- //
  clonePage('not-found-page');
}

/**
 * @param {string} page
 */
function clonePage(page) {
  const $page = document.getElementById(page);
  const pageClone = document.importNode($page.content, true);
  $main.appendChild(pageClone);
}

/**
 * @param {string} roomId
 */
async function addRoomToUser(roomId) {
  const user = await initialiseAuth();

  const existingRoomIds = user?.unsafeMetadata.roomIds || [];
  const updatedRoomIds = existingRoomIds.includes(roomId)
    ? existingRoomIds
    : [...existingRoomIds, roomId];
  try {
    await Clerk.user.update({
      unsafeMetadata: {
        roomIds: updatedRoomIds,
      },
    });
    return user;
  } catch (error) {
    console.log(error.errors);
  }
}

/** @param {{ page: string, id: string }} options - The options for rendering the page. */
async function renderPage({ page, id }) {
  try {
    resetPages();
    if (page === PILOT_PAGE || page === COPILOT_PAGE) {
      const validUser = await addRoomToUser(id);
      // pilot/copilot need to be authenticated
      if (validUser) {
        page === PILOT_PAGE ? initialisePilot(id) : initialiseCopilot(id);
      } else {
        showSignIn();
      }
    } else if (page === HOME_PAGE) {
      initialiseHomePage();
    } else {
      initialiseNotFoundPage();
    }
    currentPage = page;
  } catch (error) {
    toggleFatalWarning(error);
  }
}

// --------------------------
// ROUTER
// --------------------------
async function initialiseRouter() {
  router = new Navigo('/');
  router
    .on('/', (match) => {
      renderPage({ page: HOME_PAGE });
    })
    .on('/auth', (match) => {
      renderPage({ page: AUTH_PAGE });
    })
    .on('/copilot/:id', (match) => {
      renderPage({
        page: COPILOT_PAGE,
        id: match.data.id,
      });
    })
    .on('/pilot/:id', (match) => {
      renderPage({
        page: PILOT_PAGE,
        id: match.data.id,
      });
    })
    .notFound((x) => {
      renderPage({ page: NOT_FOUND_PAGE });
    })
    .resolve();
}

async function initialiseAuth() {
  try {
    await Clerk.load();
    return Clerk.user;
  } catch (error) {
    return false;
  }
}

// --------------------------
// INITIALISE
// --------------------------
async function initialise() {
  add('   ** INITIALISE **  ');
  // Setup router and determine
  initialiseRouter();
  if (DEBUG) {
    // Debug
    window.deleteFiles = deleteFilesFromDB;
    window.reorderFiles = reorderFilesInDB;
  }
}

window.onload = initialise;

// --------------------------
// ADD EVENT LISTENERS
// --------------------------
$reloadButton.addEventListener('click', handleDisplaySlides);
$closeSuccessButton.addEventListener('click', handleCloseSuccess);
$closeWarningButton.addEventListener('click', handleCloseWarning);
document.addEventListener('keydown', handleKeydown);

// Instances when an index and total sync will trigger:
// 1. On navigating using previous (pilot/copilot)
// 1. On navigating using next (pilot/copilot)
// 1. On success deleting all files from the DB (pilot)
// 1. On success loading all files from the DB (pilot)

// TODO
// 1. Check multiple upload sessions updated total remotely
// 1. esc and click on bg to close modal
// 1. Re-organise slides (premium option)

// IDEAS
// 1. Track mouse in copilot and show a red dot in pilot where coordinates match
